module.exports = {
  // apiBasePath: "http://localhost:2000/",
  apiBasePath: "https://dev.thecareconnexion.com/api/",
  // socketBasePath: "http://localhost:3000/",
  socketBasePath: "https://dev.thecareconnexion.com/",
  // socketBasePath: "https://staging.thecareconnexion.com/api/",
  backgroundApiBasePath: "https://sandbox.clearchecks.com/api/",
  backgroundApiToken:
    "ZWusl7IPQUzWGPyJzZ2pyXQXYJpZtCrRe2gToXQ5ZT68t3TFH7lU4lHEhLTy",
  w9Form:
    "https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=1101d310-d0b0-4917-a61f-e1ae230e8638&env=demo&acct=1582e05a-b80e-4160-b2a7-afa885128893&v=2/",
  complianceForm:
    "https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=b2f556f4-92fb-410e-b346-a91131137733&env=demo&acct=1582e05a-b80e-4160-b2a7-afa885128893&v=2",
  competencyForm:
    "https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=e42f9d00-d21c-4dd7-a09c-a6bbe47f4e14&env=demo&acct=1582e05a-b80e-4160-b2a7-afa885128893&v=2",
  fluVaccinationForm:
    "https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=f92ab8cc-a25f-4033-926a-24ac8b43e1aa&env=demo&acct=1582e05a-b80e-4160-b2a7-afa885128893&v=2",
};
